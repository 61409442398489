import { Controller } from "../lib/controller";
import { computed, observable, reaction, toJS, when } from "mobx";
import {
  asyncPause,
  flipCoin,
  isEmpty,
  isEqual,
  isNonZeroFalse,
  minutesToMilli,
  objectToMultipart,
  randomString,
  safeParseJSON
} from "../utils/helpers";
import {
  CheckUniqueResult,
  EntityProfileDuoDTO,
  Group,
  GroupType,
  Member,
  OAuth2Data,
  PersonalProfile,
  Profile,
  User,
  UserLoginData,
  VisitorRenewOAuth2Data
} from "../lib/types/dataTypes";
import { api } from "./api";
import { endpointConfig } from "../config/api";
import { AxiosResponse } from "axios";
import { UIException, UIText } from "./lang";
import { serverConfig } from "../config/api/base";
import { DataParser, DualDTOParser } from "../lib/parser";
import { Topic } from "../lib/types/topicTypes";
import { ClientUniqueIdentifiers } from "../lib/types/formTypes";
import { StdErr } from "../lib/types/miscTypes";
import flags from "../config/flags";
import { memberStatus } from "../config/constants";
import { ui } from "./ui";

// ClientStore
// Persistent storage model for Client (User) Service
export interface ClientStore {
  id: string;
  user: User;
  groupTypes: GroupType[];
  groups: Group[];
}

type InternalTopicController = Controller<{ topics: Topic[] }>;

// Client.
// Main class instance for Client (User/Group/Member/Profile) Service
// persistent data management and store.
export class Client extends Controller<ClientStore> {
  @observable initialized: boolean = false;
  @observable hasError: boolean = false;

  topicCtrl: InternalTopicController;

  preloadAvatar: (id: number, options?) => void | boolean;
  preloadDefaultAvatar: (id: number, type: "group" | "member", options?) => void;

  getExternalOAuth2RenewHandler: (refreshToken: string) => void;

  loginHandlers: Array<(param?: any) => any> = [];
  logoutHandlers: Array<(param?: any) => any> = [];

  protected parser: DataParser<any> = new DataParser<any>();
  protected profileParser: DualDTOParser = new DualDTOParser("profile");
  groupParser: DataParser<EntityProfileDuoDTO<Group>>;
  memberParser: DataParser<EntityProfileDuoDTO<Member>>;

  @observable oauth: OAuth2Data = {} as OAuth2Data;

  @computed get id(): string {
    return this.store.id;
  };
  @computed get user(): User {
    return this.store.user || {} as User;
  };
  @computed get groupTypes(): GroupType[] {
    this.storage.initProperty("groupTypes", []);
    return this.store.groupTypes;
  };
  @computed get groups(): Group[] {
    this.storage.initProperty("groups", []);
    return this.store.groups;
  };
  @computed get nonDefaultGroups(): Group[] {
    return this.groups.filter(group => group.id !== this.defaultGroup.id);
  };
  @computed get members(): Member[] {
    return this.groups.map(group => this.findMyMemberInGroup(group));
  };

  @computed get clientId(): string {
    return this.id;
  };
  @computed get userId(): User["id"] {
    return this.user.id;
  };
  @computed get defaultMember(): Member {
    return this.user.defaultMember || {} as Member;
  };
  @computed get defaultGroup(): Group {
    return this.user.defaultGroup || {} as Group;
  };
  @computed get defaultProfile(): Profile<PersonalProfile> {
    return (this.defaultGroup.profile || this.defaultMember.profile || {}) as Profile<PersonalProfile>;
  };

  @computed get allEmailAddresses(): string[] {
    return this.members.map(m => m.email);
  };

  @computed get credentialReady(): boolean {
    return !isEmpty(this.oauth) && !isEmpty(this.user);
  };
  @computed get isLoggedIn(): boolean {
    return !isEmpty(this.user) &&
      !isEmpty(this.oauth) &&
      !isEmpty(this.defaultGroup) &&
      !isEmpty(this.defaultGroup.profile) &&
      !isEmpty(this.defaultMember) &&
      !isEmpty(this.defaultMember.profile) &&
      !isEmpty(this.groups) &&
      !isEmpty(this.members);
  };
  @computed get isVisitor(): boolean {
    return (
      this.user &&
      this.user.userName &&
      !!this.user.userName.match(/{visitor}/g)
    );
  };

  constructor() {
    super();
    reaction(
      () => this.oauth,
      () => isEmpty(this.oauth) ? api.resetOAuthData() : api.updateOAuthData(toJS(this.oauth))
    );
    api.registerRenewHandler((refreshToken) => this.renewOAuth2Data(refreshToken));
    this.groupParser = new DataParser<EntityProfileDuoDTO<Group>>(this.additionalProfileParser);
    this.memberParser = new DataParser<EntityProfileDuoDTO<Member>>(this.additionalProfileParser);
  };

  initialize = () => {
    console.log("client initialize");
    return this.loadInitialData()
    .then(this.runLoginHandlers)
    .then(() => setTimeout(this.checkIdle))
    // .catch(err => (this.hasError = true) && ui.showError({
    //   err,
    //   buttons: [{
    //     text: UIText.generalConfirm,
    //     handler: this.logout
    //   }]
    // }))
    .catch(console.warn)
    .finally(() => this.initialized = true);
  };

  isLoggedInAndReady = async () => when(
    () => !!client.initialized && !!this.isLoggedIn,
  );

  loadInitialData = async () => {
    if (!this.credentialReady) return;
    await when(() => !!api.OAuth2Data);
    return Promise.all([
      this.getAndStoreGroupInitialData()
    ]);
  };


  /**
   * Client control
   */
  isMaintenance = async () => {};

  loginAndStoreUser = async (input: UserLoginData<string>): Promise<User> => {
    await this.logout();
    api.hardResetPending();

    const clientId = this.store.id = randomString();
    console.log("ClientId", clientId);

    return this.isMaintenance()
    .then(() => api.POST({
      headers: serverConfig.defaultHeaders,
      endpoint: endpointConfig.login,
      data: {
        grant_type: "password",
        username: (input.username || input.email).toLowerCase(),
        password: input.password
      },
    }))
    .then((response: AxiosResponse<OAuth2Data>) => this.updateOAuth2Data(response.data))
    .then(this.getAndStoreUser);
  };

  runLoginHandlers = () => {
    for (const handler of this.loginHandlers) {
      if (typeof handler === "function") handler();
    }
  };

  logout = async (): Promise<void> => {
    this.storage.clearStore();
    for (const handler of this.logoutHandlers) {
      if (typeof handler === "function") handler();
    }
    this.hasError = false;
  };

  logoutReload = async () => this.logout().then(() => window.location.reload());

  updateOAuth2Data = (oauth: OAuth2Data) => this.oauth = oauth;

  clearOAuth2Data = () => this.oauth = {} as OAuth2Data;

  renewOAuth2Data = async (refreshToken?: OAuth2Data["refresh_token"]) => {
    if (!refreshToken && !this.isLoggedIn) return;

    const refresh_token = refreshToken || this.oauth.refresh_token;

    const normalRenew = () => api.POST({
      renewingOAuth2Data: true,
      endpoint: endpointConfig.login,
      headers: serverConfig.defaultHeaders,
      data: {
        grant_type: "refresh_token",
        refresh_token
      }
    })
    .then((response: AxiosResponse<OAuth2Data>) => this.updateOAuth2Data(response.data));

    const visitorRenew = () => api.POST({
      renewingOAuth2Data: true,
      endpoint: endpointConfig.visitor_renew_token,
      headers: serverConfig.defaultHeaders,
      data: {
        email: this.user.email,
        refreshToken: refresh_token
      }
    })
    .then((response: AxiosResponse<VisitorRenewOAuth2Data>) => this.updateOAuth2Data((response.data || {}).oauth));

    return this.isVisitor
      ? visitorRenew()
      : this.getExternalOAuth2RenewHandler
      ? this.getExternalOAuth2RenewHandler(refresh_token)
      : normalRenew();
  };

  static flavorOAuth2Data = (oauth: OAuth2Data, id: string) => {
    if (isEmpty(oauth)) return "";
    oauth.timestamp = new Date().getTime();
    const raw = JSON.stringify(oauth);
    const salted = flipCoin()
      ? `${raw}${id}`
      : `${id}${raw}`;
    return btoa(salted);
  };

  static deflavorOAuth2Data = (cooked: string, id: string): OAuth2Data => {
    cooked = cooked || "";
    try {
      const result = safeParseJSON(atob(cooked).replace(id, ""), true);
      return result as OAuth2Data;
    }
    catch (e) {
      return {} as OAuth2Data;
    }
  };

  setLastTxId = txId => {
    if (isNonZeroFalse(txId)) return;
    if (this.user && this.user.txId) this.user.txId = txId;
  };


  /**
   * Api data getter and storers
   */
  getAndStoreUser = async (): Promise<User> =>
    api.POST({
      endpoint: endpointConfig.post_login,
      data: objectToMultipart({
        clientId: this.clientId,
        userAgent: navigator.userAgent
      })
    })
    .then((response: AxiosResponse<User>) => {
      const user = response.data || {} as User;
      if (isEmpty(user)) {
        this.logout();
        throw new UIException("RECEIVED_INVALID_CREDENTIALS");
      }
      if (user.password) delete user.password;
      return this.store.user = user;
    })
    .catch((err: StdErr<User>) => {
      const user = (err.response || {}).data || {} as User;
      // if (Number(user.status) === 2) throw new UIException("ACCOUNT_NOT_VERIFIED");
      if (Number(user.status) === 2) return Promise.resolve(this.store.user = user);
      // if (user.needPasswordReset) throw new UIException("ACCOUNT_NEED_PASSWORD_RESET");
      // if (!user.termsAgreed) throw new UIException("ACCOUNT_TERMS_NOT_AGREED");
      throw err;
    });

  getAndStoreUserGroupsAndMembers = async () =>
    Promise.all([
      api.GET(endpointConfig.get_my_groups)
      .then(this.groupParser.parseResponseArray)
      .then(groups => this.store.groups = groups)
    ]);

  getAndStoreGroupInitialData = async () =>
    api.GET(endpointConfig.group_initial_data)
    .then(response => {
      const { groupTypes, groups, defaultGroup, defaultMember } = response.data || {};
      this.store.groupTypes = groupTypes.map(this.parser.parseDTO);
      this.user.defaultGroup = this.groupParser.parseDTO(defaultGroup) as Group;
      this.user.defaultMember = this.memberParser.parseDTO(defaultMember) as Member;
      this.store.groups = groups.map(this.groupParser.parseDTO);
    });


  /**
   * Api data getters
   */
  getGroupById = async (id: Group["id"]) =>
    api.GET(endpointConfig.group_by_id(id))
    .then(this.groupParser.parseResponseObject);

  getGroupsByTypeId = async (typeId: Group["typeId"]) =>
    api.GET(endpointConfig.groups_by_type_id(typeId))
    .then(this.groupParser.parseResponseArray);

  // getGroupTypeRoles
  //
  // getGroupRolesByGroupId

  getMemberById = async (id: Member["id"]) =>
    api.GET(endpointConfig.member_by_id(id))
    .then(this.memberParser.parseResponseObject);

  getMembersByGroupId = async (groupId: Group["id"]) =>
    api.GET(endpointConfig.members_by_group_id(groupId))
    .then(this.memberParser.parseResponseArray);

  getProfileById = async (id: Profile["id"]) =>
    api.GET(endpointConfig.profile_by_id(id))
    .then(this.parser.parseResponseObject);

  checkUniqueIdentifier = async (type: ClientUniqueIdentifiers, value: string): Promise<CheckUniqueResult> => {
    if (!type || !value) return {} as CheckUniqueResult;
    let result = { exists: null, error: null };
    await api.GET({
      headers: serverConfig.defaultHeaders,
      endpoint: endpointConfig.exists(type, value.toLowerCase())
    })
    .then(response => result = response.data)
    .catch(err => {
      console.error(err);
      result.error = (err.response && err.response.data) || err.message;
    });
    return result;
  };

  getPendingEmailChange = async () =>
    api.GET(endpointConfig.get_email_change).then(response => response.data || {});

  getRevertEmailChangeDeadline = async (id: number) =>
    api.GET(endpointConfig.get_revert_email_expiry(id)).then(response => response.data);


  /**
   * Local data updaters
   */
  updateGroup = (data: Group): Group => {
    const groupId = data && data.id;
    if (!groupId || !data) return data;
    const index = this.groups.findIndex(g => g.id === groupId);
    if (index < 0) {
      this.groups.push(data);
      return data;
    }
    const group = this.groups[index];
    if (Array.isArray(data.members) && Array.isArray(group.members)) {
      for (let member of data.members) {
        if (!member) continue;
        const oMember = group.members.find(m => m.id === member.id);
        const profile = oMember && oMember.profile;
        if (isEmpty(member.profile) && profile) member.profile = profile;
        const roleList = oMember && oMember.roleList;
        if (isEmpty(member.roleList) && roleList) member.roleList = roleList;
      }
    }
    Object.assign(group, data);
    return data;
  };

  updateMember = (data: Member): Member => {
    const memberId = data && data.id;
    if (!memberId || !data) return data;
    const members = this.findMembers(m => m.id === memberId);
    for (let member of members) {
      if (isEqual(toJS(member), data)) continue;
      Object.assign(member, data);
    }
    return data;
  };

  updateProfile = (data: Profile): Profile => {
    const profileId = data && data.id;
    if (!profileId || !data) return data;
    const groups = this.findGroups(g => g.profileId === profileId);
    const members = this.findMembers(m => m.profileId === profileId);
    for (let group of groups) group.profile = data;
    for (let member of members) member.profile = data;
    if (this.defaultMember.profileId === profileId) {
      this.defaultMember.profile = data;
    }
    if (this.defaultGroup.profileId === profileId) {
      this.defaultMember.profile = data;
    }
    // Return final data;
    return data;
  };


  /**
   * Local data finders
   */
  findGroupById = groupId => this.groups.find(group => group.id === groupId) || {} as Group;

  findGroupTypeById = groupTypeId => this.groupTypes.find(gt => gt.id === groupTypeId) || {} as GroupType;

  findGroups = query => this.groups.filter(query);

  findVisibleGroups = () => {
    if (!this.isLoggedIn) return [];
    const status3GroupIds = (this.members || [])
    .filter(m => Number(m.status) === memberStatus.invited)
    .map(member => member.groupId);
    const groups = this.groups;
    return (groups || []).filter(group =>
      group.typeId > 0 && !status3GroupIds.includes(group.id) &&
      (!group.groupName || !group.groupName.match(/{scratchpad}/g))
    );
  };

  findMembers = (query, limit?: "group" | "topic") => {
    const members: Member[] = [];
    if (!isEmpty(this.groups) && limit !== "topic") {
      for (let group of this.groups) {
        const m = Array.isArray(group.members) && group.members.filter(query);
        if (m) members.push(...m);
      }
    }
    if (!isEmpty(this.topicCtrl.store.topics) && limit !== "group") {
      for (let topic of this.topicCtrl.store.topics) {
        const m = Array.isArray(topic.members) && topic.members.filter(query);
        if (m) members.push(...m);
      }
    }
    return members;
  };

  findMyMemberByGroupId = groupId => {
    const group = this.findGroupById(groupId);
    return this.findMyMemberInGroup(group);
  };

  findMyMemberInGroup = (group: Group) => {
    if (isEmpty(group)) return {} as Member;
    return (group.members || []).find(member => member.userId === this.userId) || {} as Member;
  };


  /**
   * Login logout event registers
   */
  onLogin = (handler: (param?: any) => any) =>
    !this.loginHandlers.includes(handler) && this.loginHandlers.push(handler);

  onLogout = (handler: (param?: any) => any) =>
    !this.logoutHandlers.includes(handler) && this.logoutHandlers.push(handler);

  /**
   * Other controller registers
   */
  setTopicCtrl = (controller: InternalTopicController) => this.topicCtrl = controller;

  setPreloadAvatar = method => this.preloadAvatar = method;

  setPreloadDefaultAvatar = method => this.preloadDefaultAvatar = method;

  setOAuthRenewHandler = handler => this.getExternalOAuth2RenewHandler = handler;

  /**
   * Additional data parser functions
   */
  additionalProfileParser = (input: EntityProfileDuoDTO<Group | Member>) => {
    const parsed = this.profileParser.parse(input);
    if (this.preloadAvatar && this.preloadDefaultAvatar) {
      const { avatar } = (parsed.profile || {}).data || {};
      if (avatar) this.preloadAvatar(avatar, { loadOrig: flags.loadHiresAvatar });
      this.preloadDefaultAvatar(
        parsed.id,
        !!(parsed as Member).groupId ? "member" : "group",
        { loadOrig: flags.loadHiresAvatar }
      );
    }
    return parsed;
  };

  /**
   * Idle timeout handler
   */
  checkIdle = async () => {
    if (this.isVisitor) return;
    const showPopup = () =>
      ui.showAlert({
        title: UIText.title,
        message: UIText.idleTimeout,
        buttons: [{
          text: UIText.generalConfirm,
          handler: () => window.location.reload()
        }]
      });
    if (this.isLoggedIn) {
      await api.GET({
        endpoint: endpointConfig.user,
        noRenew: true,
        noSpinner: true
      })
      .then(responseOrIs401 => {
        if (!responseOrIs401.data && responseOrIs401 === true) {
          setTimeout(() => window.location.reload(), 5000); // Auto refresh after 5s.
          return showPopup();
        }
      })
      .catch(err => {
        if (err && err.response && err.response.status === 401) return showPopup();
        throw err;
      });
    }
    await asyncPause(minutesToMilli(1));
    return this.checkIdle();
  };
}

export let client = {} as Client;
export const initClient = constructor => client = constructor;