import React from "react";
import { Button, CircularProgress, FormControl, FormHelperText, InputLabel, OutlinedInput, TextField, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import { UIText } from "../../client/lang";
import { groupTypeIds } from "../../mcb/config/constants";
import { getDisplayNameEng, getEventRealValue, isEmpty, preventDefaultStopProp } from "../../utils/helpers";
import { stateCtrl } from "../../client/state";
import { EventAvailableSharp } from "@material-ui/icons";
import { computed, observable } from "mobx";
import withJssMap from "../../components/withJssMap";
import { McbGetConnectedFormController } from "./controller";
import { StdErr } from "../../lib/types/miscTypes";
import { ui } from "../../client/ui";
import { mcbSearchCtrl } from "../../mcb/client/search";
import McbComponentPastry from "../../components/McbComponentPastry";
import { Styled } from "direflow-component";
import styles from "./styles.css";

@observer
class McbGetConnectedForm extends React.Component {
  controller: McbGetConnectedFormController = {} as McbGetConnectedFormController;

  @observable loading: boolean = true;
  @observable submitting: boolean = false;
  @observable submitted: boolean = false;
  @observable submissionMode: boolean;

  @computed get isEmailOnly(): boolean {
    return this.controller.isEmailOnly;
  };

  constructor(props) {
    super(props);
    this.controller = new McbGetConnectedFormController();
    this.controller.initialize()
    .then(this.controller.isReady)
    .then(() => this.loading = false)
    .catch(this.showError);
  };

  showError = (err: StdErr, actionName?: string) =>
    ui.showError({ err, actionName: actionName || UIText.generalError });

  handleSubmit = (event: any) => {
    preventDefaultStopProp(event);
    if (this.submitting || this.submitted) return;
    this.submitting = true;
    return this.controller.onFormSubmit()
    .then(ok => {
      this.submitting = false;
      this.submitted = ok;
      if (ok) this.submissionMode = this.isEmailOnly;
    })
    .catch(this.showError)
    .finally(() => this.submitting = false);
  };

  handleLogin = () => {
    mcbSearchCtrl.setDoNotRestoreSearchForm(true);
    stateCtrl.mcbSignInFormOptions = {};
    stateCtrl.mcbSignInFormOpen = true;
  };

  handleLogout = () => {
    this.loading = true;
    this.submitted = this.submitting = false;
    this.submissionMode = undefined;
    return this.controller.onLogout()
    .then(this.controller.isReady)
    .then(() => this.loading = false)
    .catch(this.showError);
  };

  handleBooking = () => stateCtrl.showBookingForm(this.controller.profileId);

  render() {
    const {
      serviceType,
      contactForm,
      hasInterview,
      defaultProfile
    } = this.controller;

    const handleChange = event => {
      const { value, name } = event.target;
      if (value === contactForm.get(name)) return;
      contactForm.set(name, value);
      // form.validate(name).catch(console.warn);
    };
    const validateField = event => {
      const { name } = event.target;
      const field = contactForm.getField(name);
      if (!field || field.hasInputData) return;
      return contactForm.validate(name, name === "repeat").catch(console.warn);
    };

    return <McbComponentPastry>
      <Styled styles={styles}>
        <div className={`flex column getConnected`}>
          {this.loading ? <CircularProgress /> : <>
            <div className="getConnectedHeader font-m textBold">
              {UIText.getConnected}
            </div>
            <div className="getConnectedHeader_2 font-m textBold">
              <>
                <span className="getConnectedBookingLink textUpperCase" onClick={this.handleBooking}>
                <EventAvailableSharp style={{ verticalAlign: "middle" }} />&nbsp;<span className="textUnderline">
                  {UIText.profileUtilBookButton(hasInterview)}
                  </span>
                </span> or
              </>
            </div>
            {this.isEmailOnly ? (
              <div className="getConnectedHeader_2 font-m textBold textUpperCase">
                {UIText.getConnectedContactInfo}
              </div>
            ) : (
              <div className="getConnectedHeader_2 font-m textBold textUpperCase">
                {UIText.signUp}&nbsp;/&nbsp;<span onClick={this.handleLogin} className="getConnectedSignInLink textUnderline">{UIText.login}</span>
              </div>
            )}
            <div className="getConnectedContent font-s pSans">
              {serviceType === groupTypeIds.caregiver
                ? UIText.getConnectedContentCaregivers
                : UIText.getConnectedContentServiceProviders
              }
            </div>
            {this.isEmailOnly && !isEmpty(defaultProfile) && (
              <div className="getConnectedContent pSans">
                <Typography className="textBold font-s">
                  {UIText.welcomeMessage(getDisplayNameEng(defaultProfile))}
                </Typography>
                <Typography className="getConnectedSignInLink textUnderline font-s" color="secondary" onClick={this.handleLogout}>
                  {UIText.contactFormSignOut(getDisplayNameEng(defaultProfile))}
                </Typography>
              </div>
            )}
            <form className="flex column getConnectedForm">
              <TextField
                color="secondary"
                required={!this.isEmailOnly}
                label={UIText.registrationFields.firstName}
                variant="outlined"
                name="firstName"
                error={!!contactForm.getField("firstName")._errorMessage}
                helperText={contactForm.getField("firstName")._errorMessage}
                value={contactForm.data.firstName || ""}
                disabled={this.submitting || contactForm.getField("firstName").hasInputData}
                onChange={handleChange}
                onBlur={validateField}
              />
              <TextField
                color="secondary"
                required={!this.isEmailOnly}
                label={UIText.registrationFields.lastName}
                variant="outlined"
                name="lastName"
                error={!!contactForm.getField("lastName")._errorMessage}
                helperText={contactForm.getField("lastName")._errorMessage}
                value={contactForm.data.lastName || ""}
                disabled={this.submitting || contactForm.getField("lastName").hasInputData}
                onChange={handleChange}
                onBlur={validateField}
              />
              {!this.isEmailOnly && !contactForm.getField("reference").hidden && <TextField
                color="secondary"
                required
                label={UIText.getConnectedReference}
                variant="outlined"
                name="reference"
                error={!!contactForm.getField("reference")._errorMessage}
                helperText={contactForm.getField("reference")._errorMessage || UIText.referenceHelpText}
                value={contactForm.data.reference || ""}
                disabled={this.submitting || contactForm.getField("reference").hasInputData}
                onChange={handleChange}
                onBlur={validateField}
              />}
              <FormControl variant="outlined">
                <InputLabel
                  color="secondary"
                  error={!!contactForm.getField("email")._errorMessage}
                  htmlFor="contactEmail">{UIText.registrationFields.email}</InputLabel>
                <OutlinedInput
                  id="contactEmail"
                  label={UIText.registrationFields.email}
                  color="secondary"
                  required={!this.isEmailOnly}
                  name="email"
                  value={contactForm.data.email || ""}
                  disabled={this.submitting || contactForm.getField("email").hasInputData}
                  onChange={e => {
                    if (!this.isEmailOnly) stateCtrl.onSignInFormUsernameChange(getEventRealValue(e));
                    return handleChange(e);
                  }}
                  onBlur={validateField}
                />
                <FormHelperText
                  error={!!contactForm.getField("email")._errorMessage}
                  dangerouslySetInnerHTML={{
                    __html: contactForm.getField("email")._errorMessage
                      ? contactForm.getField("email")._errorMessage
                      : ""
                  }}
                />
              </FormControl>
              <TextField
                color="secondary"
                // required
                label={UIText.registrationFields.phone}
                variant="outlined"
                name="phone"
                value={contactForm.data.phone || ""}
                disabled={this.submitting || contactForm.getField("phone").hasInputData}
                onChange={handleChange}
                onBlur={validateField}
              />
              {!this.isEmailOnly && <>
                <TextField
                  color="secondary"
                  required
                  label={UIText.registrationFields.createPassword}
                  variant="outlined"
                  name="password"
                  type="password"
                  value={contactForm.data.password || ""}
                  error={!!contactForm.getField("password")._errorMessage}
                  helperText={contactForm.getField("password")._errorMessage}
                  disabled={this.submitting || contactForm.getField("password").hasInputData}
                  onChange={handleChange}
                  onBlur={validateField}
                />
                <TextField
                  color="secondary"
                  required
                  label={UIText.registrationFields.repeat}
                  variant="outlined"
                  name="repeat"
                  type="password"
                  value={contactForm.data.repeat || ""}
                  error={!!contactForm.getField("repeat")._errorMessage}
                  helperText={contactForm.getField("repeat")._errorMessage}
                  disabled={this.submitting || contactForm.getField("repeat").hasInputData}
                  onChange={handleChange}
                  onBlur={validateField}
                />
              </>}
              <TextField
                color="secondary"
                label={UIText.getConnectedNeeds}
                variant="outlined"
                multiline
                rows={4}
                name="needs"
                value={contactForm.data.needs || ""}
                disabled={this.submitting || contactForm.getField("needs").hasInputData}
                onChange={handleChange}
                onBlur={validateField}
              />
              {/*<div className="getConnectedContent_2 font-s pSans">*/}
              {/*  {UIText.getConnectedFooter}*/}
              {/*</div>*/}
              <Button
                className={`getConnectedButton textNoTransform ${this.submitted ? "noEvents" : ""}`}
                size="large"
                variant="contained"
                color={this.submitted ? "primary" : "secondary"}
                disableElevation
                disabled={this.submitting}
                onClick={this.handleSubmit}
              >
                {this.submitting
                  ? <CircularProgress size={22} color="secondary" />
                  : this.submitted
                    ? <Typography>
                      {UIText.getConnectedSubmitted}
                    </Typography>
                    : <Typography>
                      {this.isEmailOnly ? UIText.getConnectedSubmit : UIText.generalSubmit}
                    </Typography>
                }
              </Button>
            </form>
          </>}
        </div>
      </Styled>
    </McbComponentPastry>;
  }
}

export default withJssMap(McbGetConnectedForm);
