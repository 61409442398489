import { Controller } from "../../lib/controller";
import { computed, observable, when } from "mobx";
import { Group, Member, Profile } from "../../lib/types/dataTypes";
import { McbSearchController, mcbSearchCtrl } from "../../mcb/client/search";
import { client } from "../../client/client";
import { FormLegacy } from "../../client/form.legacy";
import { asyncPause, isEmpty, safeParseJSON } from "../../utils/helpers";
import { Achievement, AggregatedRatingReviewResponseDTO, SearchResult } from "../../mcb/lib/types/dataTypes";
import { Topic } from "../../lib/types/topicTypes";
import { groupTypeIds, webAppUrl } from "../../mcb/config/constants";
import { mcbSessionCtrl } from "../../mcb/client/session";
import { removeLastName } from "../../mcb/lib/common";
import { stateCtrl } from "../../client/state";

export class McbListingProfileController extends Controller {
  loadingRetryCount: number = 0;

  @observable group: Group = {} as Group;
  @observable profile: Profile = {} as Profile;
  @observable memberProfile: Profile;

  @computed get finderContextGroup(): Group {
    return client.findGroupById(stateCtrl.finderContextGroupId);
  };
  @computed get finderContextIsMCBStaff(): boolean {
    return this.finderContextGroup.typeId === groupTypeIds.myCareBaseStaff;
  };
  @computed get finderContextGroupSelfMember(): Member {
    return this.finderContextGroup.members?.find(m => m.userId === client.userId);
  };

  @computed get groupId(): Group["id"] {
    return mcbSearchCtrl.viewGroupId;
  };
  @computed get profileId(): Profile["id"] {
    return this.group && this.group.profileId;
  };
  @computed get profileForm(): FormLegacy {
    return mcbSearchCtrl.caregiverForm || {} as FormLegacy;
  };
  @computed get availableServices(): McbSearchController["availableServices"] {
    return mcbSearchCtrl.availableServices || [];
  };
  @computed get serviceType(): McbSearchController["selectedService"] {
    return this.group.typeId;
  };
  @computed get selectedServiceName(): string {
    return (this.availableServices.find(s => s.name === this.serviceType) || {}).placeholder;
  };
  @computed get isCaregiver(): boolean {
    return this.serviceType === groupTypeIds.caregiver;
  };
  @computed get isOwnProfile(): boolean {
    return !isEmpty(client.findGroupById(this.groupId));
  };
  @computed get allResultGroups(): SearchResult[] | Group[] {
    return [
      ...mcbSearchCtrl.searchResults,
      ...mcbSearchCtrl.candidateGroups
    ];
  };
  @computed get candidateTopics(): Topic[] {
    return mcbSearchCtrl.candidateTopics || [];
  };
  @computed get candidateProfiles(): Profile[] {
    const groups = this.allResultGroups.filter(g => this.candidateTopics.some(t => t.groupIdList && t.groupIdList.includes(g.id)));
    return groups.map(group => group.profile);
  };
  @computed get savedProfileIds(): Profile["id"][] {
    if (stateCtrl.finderContextGroupId) return stateCtrl.marketResultsPopupSavedProfileIds;
    return this.candidateProfiles.map(profile => profile.id);
  };

  @computed get isCheckingShortlists(): boolean {
    return !!mcbSearchCtrl.isCheckingShortlists[mcbSearchCtrl.scratchpadGroup?.id];
  };
  @computed get isCheckingCandidates(): boolean {
    return mcbSearchCtrl.isCheckingCandidates;
  };
  @computed get shortlistingAvailable(): boolean {
    return mcbSearchCtrl.shortlistingAvailable;
  };

  @computed get loading(): boolean {
    return mcbSessionCtrl.runningAuthChangeSequence
      || !mcbSearchCtrl.ready
      || !client.initialized;
  };

  @computed get rating(): AggregatedRatingReviewResponseDTO {
    return mcbSearchCtrl.findAggregatedRatingForGroup(this.group);
  };
  @computed get ratingLoading(): boolean {
    return isEmpty(this.rating) || mcbSearchCtrl.ratingLoading;
  };

  @computed get hasInterview(): boolean {
    const data = mcbSearchCtrl.findHasInterviewAppointmentData(
      mcbSearchCtrl.scratchpadGroup.id,
      this.groupId
    );
    return data?.hasInterview;
  };

  @computed get achievement(): Achievement {
    return mcbSearchCtrl.findProfileAchievement(this.profile);
  };

  @computed get adminLink(): string {
    if (!this.finderContextIsMCBStaff) return "";
    return `${webAppUrl}/AdminCentre?type=${this.group.typeId === groupTypeIds.caregiver ? "3" : "providers"}&profile=${this.profile.id}`;
  };

  @computed get showChat(): boolean {
    return mcbSessionCtrl.isEmbedded
      && stateCtrl.finderContextGroupId
      && stateCtrl.marketResultsPopupSavedProfileIds
      && stateCtrl.marketResultsPopupSavedProfileIds.includes(this.profileId);
  };

  initialize = async () => this.loadAllData().catch(this.retry);

  loadAllData = async () =>
    this.getGroupById()
    .then(this.getProfileById)
    .then(this.getRatingForGroup)
    .then(this.getAchievement)
    .then(this.getHasInterviewAppointmentData)

  isReady = async () => when(() =>
    mcbSearchCtrl.ready &&
    !isEmpty(this.group) &&
    !isEmpty(this.profile)
  );

  retry = async (err: Error) => {
    if (this.loadingRetryCount >= 3) throw err;
    await asyncPause(2000);
    this.loadingRetryCount++;
    return this.loadAllData()
    .catch(this.retry);
  };

  findGroupRatingScore = (profile: Profile) => mcbSearchCtrl.findGroupRatingScore(this.group);

  getGroupById = async () => {
    await client.isLoggedInAndReady();
    if (!this.groupId) return;
    return client.getGroupById(this.groupId)
    .then(group => this.group = group);
  };

  getProfileById = async () => {
    await client.isLoggedInAndReady();
    if (!this.profileId) return;
    return client.getProfileById(this.profileId)
    .then(this.checkIsApprovedProfile)
    .then(profile => profile && (this.profile = removeLastName(profile)));
  };

  getRatingForGroup = () => setTimeout(() => mcbSearchCtrl.getRatingForGroup(this.group));

  getAchievement = () => setTimeout(() => mcbSearchCtrl.getAchievementForGroup(this.group));

  getHasInterviewAppointmentData = () => setTimeout(() => mcbSearchCtrl.getHasInterviewAppointmentDataForGroup({
    organizerGroupId: mcbSearchCtrl.scratchpadGroup.id,
    providerGroupId: this.groupId
  }));

  checkIsApprovedProfile = async profile => {
    if (isEmpty(profile)) return;
    const data = typeof profile.data === "string" ? safeParseJSON(profile.data) : profile.data;
    if (!data["approvalOfCandidateConfirmedByReference"]) {
      setTimeout(stateCtrl.closeMcbListingProfile);
      return null;
    }
    return profile;
  };

  getProfileAvatarUri = (profile: Profile) => mcbSearchCtrl.getProfileAvatarUri(profile);

  getProfileAbilities = profile => mcbSearchCtrl.getProfileAbilities(profile);

  getProfileLanguages = profile => mcbSearchCtrl.getProfileLanguages(profile);

  onProfileSave = async () => {
    if (stateCtrl.finderContextGroupId) return stateCtrl.onMarketResultsPopupSave(null, this.profileId);

    return mcbSearchCtrl.addToShortlist(this.groupId);
  };

  onProfileUnSave = async () => {
    if (stateCtrl.finderContextGroupId) return stateCtrl.onMarketResultsPopupSave(null, this.profileId);

    const topic = this.candidateTopics.find(t => t.groupIdList.includes(this.groupId));
    if (!topic) return;
    return mcbSearchCtrl.removeFromShortlist(topic.id);
  };
}