import React from "react";
import { observer } from "mobx-react";
import { UIText } from "../../client/lang";
import { Styled } from "direflow-component";
import { computed, observable } from "mobx";
import { StdErr } from "../../lib/types/miscTypes";
import { ui } from "../../client/ui";
import styles from "./styles.css";
import { capitalize, preventDefaultStopProp, whenFulfill } from "../../utils/helpers";
import { Button, Card, CardContent, CircularProgress, IconButton, TextField, Typography } from "@material-ui/core";
import McbComponentPastry from "../../components/McbComponentPastry";
import withJssMap from "../../components/withJssMap";
import { McbSignInFormController } from "./controller";
import { stateCtrl } from "../../client/state";
import { AlertModalContent } from "../../components/AlertModal";
import { Close } from "@material-ui/icons";
import { signUpUrl } from "../../mcb/config/constants";
import { LoginBoxExtraLink } from "../../lib/types/stateTypes";
import { mcbSessionCtrl } from "../../mcb/client/session";
import { client } from "../../client/client";

@observer
class McbSignInForm extends React.Component {
  controller: McbSignInFormController = {} as McbSignInFormController;

  @observable loading: boolean = false;
  @observable forgotPassword: boolean = false;

  @observable resetInfo: string = "";
  @observable resetInfoOpen: boolean = false;

  @computed get extraLinks(): LoginBoxExtraLink[] {
    return (stateCtrl.mcbSignInFormOptions || {}).extraLinks || [];
  };
  @computed get subHeading(): string {
    return (stateCtrl.mcbSignInFormOptions || {}).subHeading;
  };
  @computed get hideCreateAccount(): boolean {
    return (stateCtrl.mcbSignInFormOptions || {}).hideCreateAccount;
  };
  @computed get hideForgotPassword(): boolean {
    return (stateCtrl.mcbSignInFormOptions || {}).hideForgotPassword;
  };

  constructor(props) {
    super(props);
    this.controller = new McbSignInFormController();
  };

  showError = (err: StdErr, actionName?: string) =>
    ui.showError({ err, actionName: actionName || UIText.loginError });

  isUserLoggedIn = (): boolean =>
    client.isLoggedIn
    && !mcbSessionCtrl.isValidVisitor
    && !mcbSessionCtrl.runningAuthChangeSequence;

  handleFieldChange = event => {
    preventDefaultStopProp(event);
    const { target } = event;
    if (!target) return;
    const { name, value } = target;
    if (!name || !target) return;
    return this.controller[`on${capitalize(name)}Change`](value);
  };

  handleLogin = event => {
    preventDefaultStopProp(event);
    this.loading = true;
    return this.controller.onLogin()
    .then(() => whenFulfill(() => this.isUserLoggedIn()))
    .then(() => stateCtrl.closeSignInform(null, true))
    .then(() => stateCtrl.signInPostRefresh && window.location.reload())
    .catch(this.showError)
    .finally(() => this.loading = false);
  };

  handleSignUp = event => {
    preventDefaultStopProp(event);
    window.open(signUpUrl, "_blank");
  };

  handleForgotPassword = event => {
    preventDefaultStopProp(event);
    this.controller.resetError();
    this.forgotPassword = !this.forgotPassword;
  };

  handleForgotPasswordSubmit = event => {
    preventDefaultStopProp(event);
    this.loading = true;
    return this.controller.onReset()
    .then(this.showResetInfo)
    .catch(this.showResetInfo)
    .finally(() => this.loading = false);
  };

  handleClose = event => {
    preventDefaultStopProp(event);
    return stateCtrl.closeSignInform(event);
  };

  showResetInfo = info => {
    this.resetInfo = info === true ? UIText.resetRequestSent : info;
    this.resetInfoOpen = true;
  };

  render() {
    const { loginData, resetPasswordData, loginError } = this.controller;

    return <McbComponentPastry>
      <Styled styles={styles}>
        <Card className="mcbSignInForm">
          {this.resetInfoOpen ? (
            <AlertModalContent
              buttons={[]}
              title={UIText.resetPassword}
              message={this.resetInfo}
              onClose={() => {
                this.forgotPassword = false;
                this.resetInfoOpen = false;
              }}
            />
          ) : <CardContent>
            <div className="flex justify-content-between align-items-center" style={{ marginBottom: "10px" }}>
              <div className="flex column headingContainer">
                <Typography className="font-m textBold mcbSignInFormTitle" color="primary">
                  {this.forgotPassword ? UIText.resetPassword : UIText.welcome}
                </Typography>
                {this.subHeading && !this.forgotPassword && (
                  <Typography className="font-s textBold mcbSignInFormTitle">
                    {this.subHeading}
                  </Typography>
                )}
              </div>
              <IconButton
                className="mcbSignInFormClose closeButton"
                onClick={this.handleClose}
                color="secondary"
              >
                <Close />
              </IconButton>
            </div>
            <form
              className="mcbLoginFields flex column align-items-center"
              noValidate
              onSubmit={this.handleLogin}
            >
              {this.forgotPassword ? <>
                <TextField
                  required
                  variant="outlined"
                  label={UIText.loginFieldUsername}
                  name="resetPasswordId"
                  value={resetPasswordData.username}
                  disabled={this.loading}
                  onChange={this.handleFieldChange}
                />
              </> : <>
                <TextField
                  required
                  variant="outlined"
                  error={loginError.username}
                  label={UIText.email}
                  name="username"
                  value={loginData.username}
                  disabled={this.loading}
                  onChange={this.handleFieldChange}
                />
                <TextField
                  required
                  variant="outlined"
                  error={loginError.password}
                  label={UIText.password}
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  value={loginData.password}
                  disabled={this.loading}
                  onChange={this.handleFieldChange}
                />
              </>}
              <input type="submit" style={{ display: "none" }} />
              {!this.forgotPassword && this.extraLinks.map(linkOption => !linkOption.below && (
                <Typography key={linkOption.text} className="textBold mcbSignInFormLink" onClick={linkOption.handler}>
                  {linkOption.text}
                </Typography>
              )).filter(Boolean)}
              {!this.forgotPassword && !this.hideCreateAccount && <Typography className="textBold mcbSignInFormLink" onClick={this.handleSignUp}>
                {UIText.dontHaveAccount}
              </Typography>}
              {!this.hideForgotPassword && <Typography className="textBold mcbSignInFormLink" onClick={this.handleForgotPassword}>
                {this.forgotPassword ? UIText.returnToLogin : UIText.forgotPassword}
              </Typography>}
              {!this.forgotPassword && this.extraLinks.map(linkOption => !!linkOption.below && (
                <Typography key={linkOption.text} className="textBold mcbSignInFormLink" onClick={linkOption.handler}>
                  {linkOption.text}
                </Typography>
              )).filter(Boolean)}
              <Button
                disabled={this.loading || (
                  this.forgotPassword
                    ? !resetPasswordData.username
                    : !loginData.username || !loginData.password
                )}
                className="textNoTransform mcbSignInFormButton"
                size="large"
                variant="contained"
                color="secondary"
                disableElevation
                onClick={this.forgotPassword ? this.handleForgotPasswordSubmit : this.handleLogin}
              >
                {this.loading
                  ? <CircularProgress size={24} />
                  : <Typography>{this.forgotPassword ? UIText.generalSubmit : UIText.login}</Typography>
                }
              </Button>
            </form>
          </CardContent>}
        </Card>
      </Styled>
    </McbComponentPastry>;
  }
}

export default withJssMap(McbSignInForm);