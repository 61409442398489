import { Controller } from "../../lib/controller";
import { action, autorun, computed, IObservableArray, observable, reaction, toJS, when } from "mobx";
import { AvailabilityFinderContainerProps } from "./index";
import {
  groupTypeIds,
  showWeekOfDay,
  TimeBlockTimeStringMap,
  timeOfDayDescription,
  timeOfDayName,
  topicTypeIds,
  typeClassIds
} from "../../config/constants";
import {
  AvailabilityBlockId,
  AvailabilityLocation,
  AvailabilitySet,
  CAFCaregiverGroup,
  CAFCaregiverProfileData
} from "../../lib/types/caf/cafProfileTypes";
import { Group, PlacesAutocompleteResult, Profile, SearchCacheData } from "../../lib/types/dataTypes";
import {
  abbvLastNameDisplayNameEng,
  addAlpha,
  arrayFlat,
  asyncPause,
  capitalize,
  es6GetCurrURLQueryParams,
  getDisplayNameEng,
  isEmpty,
  isEqual,
  safeParseJSON,
  serializeObject
} from "../../utils/helpers";
import { computedFn } from "mobx-utils";
import { endpointConfig } from "../../config/api";
import { api } from "../../client/mcb-bridge/api";
import { mcbSearchCtrl } from "../../client/mcb-bridge/search";
import { mcbBridge } from "../../client/mcb-bridge";
import { theme } from "../../config/styles/theme";
import { stateCtrl } from "../../client/mcb-bridge/state";
import { mcbSessionCtrl } from "../../client/mcb-bridge/session";
import { client } from "../../client/mcb-bridge/client";
import flags from "../../config/flags";
import { ui } from "../../client/mcb-bridge/ui";
import { Coordinate } from "../../lib/types/caf/geoDataTypes";
import { FormLegacy } from "../../client/mcb-bridge/form.legacy";
import { JSONString } from "../../lib/types/miscTypes";
import moment from "moment-timezone";
import { placesApi } from "../../client/mcb-bridge/places";
import { debounce } from "@material-ui/core";
import { Topic, TopicPrimitive } from "../../lib/types/topicTypes";
import { topicCtrl } from "../../client/mcb-bridge/topic";
import { serverConfig } from "../../config/api/base";
import { AxiosResponse } from "axios";
import { globalCafHook } from "../../client/ref";

export interface AvailabilityFinderStore {}

type Map = google.maps.Map;

export class AvailabilityFinderController extends Controller<AvailabilityFinderStore> {
  readonly searchResultColor = theme.palette.secondary.main;
  readonly halfSearchResultColor = addAlpha(theme.palette.secondary.main, .5);

  searchDebouncer;
  readonly searchDebounceInterval = 500;

  @observable props: AvailabilityFinderContainerProps;

  @observable mapZoom: number;
  @observable mapTypeId: google.maps.MapTypeId;
  @observable mapCenter: google.maps.LatLng;

  @observable searchLoading: boolean = false;
  @observable ssrSearchCache: SearchCacheData;

  @observable shortlistTopics: IObservableArray<Topic> = observable([]);
  @observable candidateTopics: IObservableArray<Topic> = observable([]);
  @observable candidateGroups: IObservableArray<Group> = observable([]);

  @observable selectedBlocks: IObservableArray<AvailabilityBlockId> = observable([]);

  @observable searchForm: FormLegacy;
  searchFormDisposers = [];
  @observable searchFormJSON: JSONString;
  @observable searchFormClicked: boolean;

  @observable searchResultDebug: boolean;

  @observable collapsedLocationText: string;
  @observable collapsedGridText: string;

  @observable shortlistLoading: boolean = false;
  @observable criteriaLoading: boolean = true;
  @computed get searchResultGroups(): CAFCaregiverGroup[] {
    return this.ssrSearchCache?.groups || [];
  };

  @computed get searchGroup(): Group {
    const id = this.props.searchGroup?.id;
    return client.findGroupById(id);
  };

  @computed get enteredLocation(): PlacesAutocompleteResult {
    return placesApi?.getEnteredLocation();
  };

  @computed get careCircleSearchForm(): FormLegacy {
    return mcbSearchCtrl?.careCircleSearchForm || {} as FormLegacy;
  };

  @computed get storedCoordinates(): Coordinate {
    return !isEmpty(this.enteredLocation) && {
      lat: this.enteredLocation.lat,
      lng: this.enteredLocation.lng
    };
  };

  @computed get mapViewportCenter(): Coordinate {
    return isEmpty(this.storedCoordinates)
      ? this.props.cityCoordinates
      : this.storedCoordinates;
  };

  @computed get searchedProfiles(): Profile<CAFCaregiverProfileData>[] {
    return this.searchResultGroups.map(group => group.profile || {} as Profile<CAFCaregiverProfileData>);
  };

  @computed get searchedSelectedProfiles(): Profile<CAFCaregiverProfileData>[] {
    return this.searchedProfiles.filter(profile => (
      this.selectedBlocks.some(keyStr => this.getProfileIsInSelectedBlock(profile, keyStr))
    ));
  };

  @computed get searchFormDirty(): boolean {
    return this.searchForm?.isDirty;
  };

  @computed get websiteShortlist(): Topic | null {
    return this.shortlistTopics.find(t => t.groupIdList.includes(this.searchGroup?.id));
  };

  @computed get allResultGroups(): CAFCaregiverGroup[] | Group[] {
    const groups = [
      ...this.searchResultGroups,
      ...this.candidateGroups
    ];
    return Array.from(new Set(groups.map(g => g.id)))
    .map(id => groups.find(g => g.id === id));
  };

  @computed get candidateTopicGroups(): Group[] {
    return this.allResultGroups.filter(g => this.candidateTopics.some(t => t.groupIdList && t.groupIdList.includes(g.id)));
  };

  @computed get candidateProfiles(): Profile[] {
    const groups = this.candidateTopicGroups || [];
    return groups.map(group => group.profile);
  };

  @computed get savedProfileIds(): number[] {
    return this.candidateProfiles.map(profile => profile.id);
  };

  // };
  @computed get showViewAllButton(): boolean {
    return !this.searchLoading &&
      (flags.mcbFinderUseAdvancedFilter
        || (this.selectedBlocks.length > 1 && this.searchedSelectedProfiles.length > 1)
      );
  };

  @computed get searchResultCount(): number {
    return isEmpty(this.selectedBlocks) ? 0 : this.searchedSelectedProfiles.length;
  };

  constructor(props) {
    super();
    this.updateProps(props);
  }

  getProfileIsInSelectedBlock = computedFn((profile: Profile<CAFCaregiverProfileData>, keyStr: AvailabilityBlockId): boolean => {
    const { availability } = profile.data || {};
    const isAvailable = (availability: AvailabilitySet, dow: number, blockTimeStrings: string[]) => {
      const { dayOfWeek, start, end } = availability || {};
      if (!start || !end) return false;
      if (dayOfWeek !== dow) return false;
      if (isEmpty(blockTimeStrings)) return false;
      const blockStart = moment(blockTimeStrings[0], "HH:mm:ss").unix();
      const blockEnd = moment(blockTimeStrings[1], "HH:mm:ss").unix();
      const availStart = moment(start, "HH:mm:ss").unix();
      const availEnd = moment(end, "HH:mm:ss").unix();
      const withinStart = (availStart >= blockStart && availStart < blockEnd) || availStart < blockStart;
      const withinEnd = (blockEnd >= availEnd && availEnd > blockStart) || availEnd > blockEnd;
      return withinStart && withinEnd;
    };
    const matchedAvailabilitySets = Array.isArray(availability) && (availability || []).filter(a => {
      const dow = Number(keyStr[0]);
      const tod = Number(keyStr.slice(1));
      const timeStrings = TimeBlockTimeStringMap[tod];
      const overnightDow = tod === 3 ? (dow === 6 ? 0 : dow + 1) : undefined;
      const nextDaySets = overnightDow !== undefined && availability.filter(a => a.dayOfWeek === overnightDow);
      if (overnightDow) {
        const nextDayAvailable = nextDaySets.some(a => isAvailable(a, overnightDow, ["00:00:00", "06:00:00"]));
        return isAvailable(a, dow, timeStrings) && nextDayAvailable;
      }
      return isAvailable(a, dow, timeStrings);
    });
    return !isEmpty(matchedAvailabilitySets);
  });

  getBlockIsSelected = computedFn((keyStr: AvailabilityBlockId): boolean =>
    (this.searchResultDebug && !keyStr.includes("-")) || this.selectedBlocks.includes(keyStr));

  getBlockIsInSearchResult = computedFn((keyStr: AvailabilityBlockId): boolean =>
    this.searchedProfiles.some(profile => this.getProfileIsInSelectedBlock(profile, keyStr)));

  getBlockColor = computedFn((keyStr: AvailabilityBlockId): string => this.getBlockIsSelected(keyStr) ? this.searchResultColor : "");

  getBlockCoverText = computedFn((keyStr: AvailabilityBlockId): string => {
    if (this.searchLoading) return "―";
    if (!this.getBlockIsSelected(keyStr)) return null;
    const profiles = this.searchedProfiles.filter(profile => this.getProfileIsInSelectedBlock(profile, keyStr));
    if (isEmpty(profiles)) return "0";
    return this.searchResultDebug
      ? profiles.map(getDisplayNameEng).join("\n")
      : profiles.length.toString();
  });

  getBlockShowDeselectButton = computedFn((keyStr: AvailabilityBlockId): boolean => {
    if (flags.mcbFinderUseAdvancedFilter) return false;
    return this.selectedBlocks.includes(keyStr);
  });

  getGroupsByProfiles = computedFn((profiles: Profile<CAFCaregiverProfileData>[]) => {
    if (isEmpty(profiles)) return;
    return this.searchResultGroups.filter(group => (
      profiles.some(profile => profile.id === group.profileId)
    ));
  });

  getGroupIdsByProfiles = computedFn((profiles: Profile[]): number[] => (
    this.searchResultGroups.map(group => (
      profiles.some(profile => profile.id === group.profileId) && group.id
    )).filter(Boolean)
  ));

  handleOpenResultList = computedFn(() => {
    if (!globalCafHook) return stateCtrl.openMarketResultsPopup(this.getGroupsByProfiles(this.searchedSelectedProfiles));
    const groups = this.searchResultGroups.filter(g => this.searchedSelectedProfiles.some(p => p.id === g.profileId));
    globalCafHook.setSearchedProfiles(groups, this.ssrSearchCache, true);
    this.updateCollapsedGridText();
  });

  handleOpenSavedList = computedFn(() => {
    return stateCtrl.openMarketResultsPopup(this.candidateTopicGroups);
  });

  handleBlockCoverTextClick = computedFn((keyStr: AvailabilityBlockId): void => {
    const blockCoverText = this.getBlockCoverText(keyStr);
    const blockHasResults: boolean = blockCoverText && !["―", "0"].includes(blockCoverText);
    if (!blockHasResults) return;
    const profiles = this.searchedProfiles.filter(profile => this.getProfileIsInSelectedBlock(profile, keyStr));
    if (isEmpty(profiles)) return;
    if (!globalCafHook) return stateCtrl.openMarketResultsPopup(this.getGroupsByProfiles(profiles));
    // return stateCtrl.openMarketResultsPopup(this.getGroupsByProfiles(profiles));
    const groups = this.searchResultGroups.filter(g => profiles.some(p => p.id === g.profileId));
    globalCafHook.setSearchedProfiles(groups, this.ssrSearchCache, true);
    const dow = Number(keyStr.substring(0, 1));
    const tod = Number(keyStr.substring(1, 3));
    this.updateCollapsedGridText(dow, tod);
  });

  @action initialize = async () => {
    await this.loadAllData();
    return this.applySideEffects();
  };

  @action loadAllData = async () => Promise.all([
    this.setSearchForm(),
    this.getOrCreateShortlistTopic(),
    this.restoreCoordinates(),
    this.restoreSelectedBlocks()
  ]);

  @action updateProps = (props: AvailabilityFinderContainerProps) =>
    this.props = props;

  @action applySideEffects = () => {
    this.disposers.push(reaction(() => this.searchGroup?.id, this.loadAllData));
    this.disposers.push(reaction(() => this.searchResultDebug, this.queueSearch));
    this.disposers.push(reaction(() => [this.searchFormJSON, this.storedCoordinates], this.queueSearch));
    this.disposers.push(reaction(() => [this.storedCoordinates, this.selectedBlocks.join("")], this.updateUrl));
    this.disposers.push(reaction(() => [this.collapsedLocationText, this.collapsedGridText], this.updateHostCollapsedText));
    this.disposers.push(reaction(() => this.selectedBlocks.join(""), this.saveSelectedBlocks));
    this.disposers.push(reaction(() => this.savedProfileIds, this.syncSavedProfileIds));
  };

  @action handleMapCenterChange = (value: PlacesAutocompleteResult) => {
    placesApi?.setEnteredLocation(toJS(value));
    if (typeof value === "object") this.collapsedLocationText = value.formattedAddress || "";
    if (globalCafHook?.onLocationChange) {
      setTimeout(() => globalCafHook?.onLocationChange(this.enteredLocation));
    }
  };

  @action handleBlockSelect = (keyStr: AvailabilityBlockId) => {
    return this.selectedBlocks.includes(keyStr)
      ? this.selectedBlocks.remove(keyStr)
      : this.selectedBlocks.push(keyStr);
  };

  @action handleDeselectAllBlocks = () => this.selectedBlocks.clear();

  @action handleMapZoom = (zoom: number) => this.mapZoom = zoom;

  @action setMapTypeId = (id: google.maps.MapTypeId) => this.mapTypeId = id;

  @action setMapCenter = (center: google.maps.LatLng) => {
    this.mapCenter = center;
    // CAF 2.0 direct set center on move.
    this.handleMapCenterChange({
      lat: center.lat(),
      lng: center.lng()
    });
    const { onMapDrag } = this.props;
    onMapDrag && onMapDrag();
  };

  setMapGlobal = (map: Map) => {
    const { setMapGlobal } = this.props;
    setMapGlobal && setMapGlobal(map);
  };

  updateUrl = () => {
    const location = [
      (this.storedCoordinates?.lat || this.props.cityCoordinates?.lat).toFixed(7),
      (this.storedCoordinates?.lng || this.props.cityCoordinates?.lng).toFixed(7)
    ];
    const cafcoords = location.join(",");
    const cafgrid = this.selectedBlocks.join(",");
    const queryParams = es6GetCurrURLQueryParams();
    const newQueryParams = {
      ...queryParams,
      cafcoords,
      cafgrid
    };
    window.history.pushState(
      null,
      null,
      `${window.location.pathname}?${serializeObject(newQueryParams, true)}`
    );
  };

  updateCollapsedGridText = (dow?: number, tod?: number) => {
    if (!globalCafHook) return;
    if (dow === undefined || tod === undefined) return this.collapsedGridText = "MATCHING ALL SELECTIONS";
    this.collapsedGridText = `AVAILABLE ${showWeekOfDay[dow]} ${timeOfDayName[tod]} Anytime between ${timeOfDayDescription[tod]}`;
  };

  updateHostCollapsedText = () => {
    if (!globalCafHook) return;
    globalCafHook.setCollapsedText(this.collapsedLocationText, this.collapsedGridText);
  };

  queueSearch = () => {
    clearTimeout(this.searchDebouncer);
    this.searchDebouncer = setTimeout(() => this.searchProfiles(), this.searchDebounceInterval);
  };

  @action setSearchForm = async () => {
    await mcbBridge.isInitialized();
    if (!globalCafHook?.bypassMode) await when(() => !!this.searchGroup);
    const fields = (globalCafHook?.getSearchFields && globalCafHook.getSearchFields()) || await mcbSearchCtrl.getSearchFields();
    const data = (this.searchGroup?.profile || {}).data || {};
    this.searchFormDisposers.forEach(dispose => dispose());
    this.searchForm = mcbBridge.genFormLegacy(fields, toJS(data));
    this.searchFormDisposers.push(autorun(() => mcbBridge.assistEnglishSelection(this.searchForm)));
    this.searchFormDisposers.push(reaction(() => this.searchForm.data, this.updateSearchFormData));
    stateCtrl.setCustomSearchForm(this.searchForm);
    this.searchForm.setField("provinces", {
      hidden: true
    });
    this.searchForm.setField("territories", {
      hidden: true
    });
    this.searchForm.setField("municipalities", {
      hidden: true
    });
    this.searchForm.resetDirty();
    this.updateSearchFormData();
    this.criteriaLoading = false;
  };

  @action setSearchFormClicked = () => this.searchFormClicked = true;

  @action restoreCoordinates = async () => {
    const data = (this.searchGroup?.profile || {}).data || {};
    if (isEmpty(data)) return;
    const profileLocation: AvailabilityLocation = data?.location || {};
    const location = profileLocation.location;
    const enteredLocation = this.enteredLocation;
    const { cafcoords } = es6GetCurrURLQueryParams();
    const urlCoordArr = cafcoords && safeParseJSON(`[${decodeURIComponent(cafcoords)}]`);
    const urlLocation = !isEmpty(urlCoordArr) && { lat: urlCoordArr[0], lng: urlCoordArr[1] };
    if (isEmpty(location) && isEmpty(enteredLocation) && isEmpty(urlLocation)) {
      return this.handleMapCenterChange(this.props.cityCoordinates);
    }
    const lat = location?.lat || urlLocation?.lat || enteredLocation?.lat;
    const lon = location?.lon || urlLocation?.lng || enteredLocation?.lng;
    if (lat && lon) {
      this.handleMapCenterChange({
        lat: Number(lat),
        lng: Number(lon)
      });
    }
  };

  @action restoreSelectedBlocks = async () => {
    await this.storage.isReady();
    const { cafgrid } = es6GetCurrURLQueryParams();
    if (!this.props.appIsLoggedIn || cafgrid) {
      const urlBlocks = cafgrid && decodeURIComponent(cafgrid).split(",") as AvailabilityBlockId[];
      return urlBlocks && this.selectedBlocks.replace(urlBlocks.map(id => id.toString()));
    }
    const data = (this.searchGroup?.profile || {}).data || {};
    if (isEmpty(data)) return;
    const needAvailability: AvailabilityBlockId[] = data?.needAvailability || [];
    return this.selectedBlocks.replace(needAvailability);
  };

  searchIsReady = async () => Promise.all([
    client.isLoggedInAndReady(),
    when(() => !mcbSessionCtrl.runningAuthChangeSequence),
    when(() => !isEmpty(this.searchForm)),
    when(() => !!this.searchGroup?.id)
  ]);

  @action updateSearchFormData = async () => {
    await when(() => !this.searchLoading);
    if (isEmpty(this.searchForm.data)) return;
    const json = this.searchForm.toJSON();
    if (!json) return;
    if (isEqual(this.searchFormJSON, json)) return;
    this.searchFormJSON = json;
    // if (!this.searchFormClicked) return;
    if (isEmpty(this.enteredLocation) && isEmpty(this.props.cityCoordinates)) return;
    if (!globalCafHook) {
      console.log("Criteria form triggered search.");
      this.queueSearch();
    }
  };

  @action searchProfiles = async () => {
    if (this.searchLoading) return;
    this.searchLoading = true;
    await mcbBridge.isInitialized();
    if (!globalCafHook?.bypassMode) await this.searchIsReady().then(() => console.log("ready"));
    const data = this.searchFormJSON;
    const groupId = this.searchGroup?.id || 0;
    const typeClassId = typeClassIds.careReceiverProfile.v3.id; // Both groups are using same typeClass
    const location = [
      this.storedCoordinates?.lat || this.props.cityCoordinates?.lat,
      this.storedCoordinates?.lng || this.props.cityCoordinates?.lng
    ];
    if (isEmpty(location)) return;
    return api.POST({
      endpoint: endpointConfig.search_caf_marketplace,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
        ...serverConfig.defaultHeaders
      },
      data: {
        groupId,
        data,
        typeClassId,
        location,
        // isAdmin: isSnSOrAdmin(client.user) ? 1 : 0,
        isAdmin: 0,
        groupTypeIds: [groupTypeIds.caregiver],
        returnAllWithCommuteTime: this.searchResultDebug
      }
    })
    .then((response: AxiosResponse<SearchCacheData>) => response.data)
    .then(searchCache => this.ssrSearchCache = searchCache)
    .then(() => {
      if (!globalCafHook) return;
      globalCafHook?.setSearchedProfiles(this.searchResultGroups, this.ssrSearchCache);
    })
    .catch(err => ui.showError({ err, actionName: "Availability Finder" }))
    .finally(() => this.searchLoading = false);
  };

  @action handleSaveSearchForm = async () => {
    if (!this.searchFormDirty) return;
    if (!this.props.appIsLoggedIn) return;
    const newData = toJS(this.searchForm.data);
    if (isEmpty(newData)) return;
    newData.needAvailability = toJS(this.selectedBlocks);
    this.criteriaLoading = true;
    let oldData = this.searchGroup?.profile?.data;
    if (isEmpty(oldData)) return;
    oldData = typeof oldData === "string" ? safeParseJSON(oldData) : oldData;
    const data = JSON.stringify(Object.assign(oldData, newData));
    return api.PATCH({
      endpoint: endpointConfig.update_profile_with_audit(this.searchGroup?.profileId),
      data: { data }
    })
    .then(() => this.searchForm.resetDirty())
    .finally(() => this.criteriaLoading = false);
  };

  @action saveSelectedBlocks = debounce(async () => {
    if (!this.props.appIsLoggedIn) return;
    const needAvailability = toJS(this.selectedBlocks);
    let careCircleProfileData = this.searchGroup?.profile?.data;
    if (isEmpty(careCircleProfileData)) return;
    careCircleProfileData = typeof careCircleProfileData === "string"
      ? safeParseJSON(careCircleProfileData)
      : careCircleProfileData;
    const data = JSON.stringify({
      ...careCircleProfileData,
      needAvailability
    });
    return api.PATCH({
      endpoint: endpointConfig.update_profile_with_audit(this.searchGroup?.profileId),
      data: { data }
    })
    .then(() => client.getProfileById(this.searchGroup?.profileId))
    .then(client.updateProfile);
  }, 500);

  getOrCreateFinderShortlist = async (id: Group["id"]): Promise<Topic> =>
    api.GET(endpointConfig.get_or_create_finder_shortlist(id))
    .then(response => response.data);

  @action getOrCreateShortlistTopic = debounce(async () => {
    if (globalCafHook?.bypassMode) return;
    if (this.shortlistLoading) return;
    this.shortlistLoading = true;
    await asyncPause();
    await this.searchIsReady();
    const shortListTopic = this.shortlistTopics.find(t => (
      t.typeId === topicTypeIds.shortlist && t["groupIdList"].includes(this.searchGroup?.id)
    ));
    if (!isEmpty(shortListTopic)) {
      return this.getCandidateTopics()
      .finally(() => this.shortlistLoading = false);
    }
    return this.getOrCreateFinderShortlist(this.searchGroup?.id)
    .then(topic => {
      if (isEmpty(topic)) return;
      const existing = this.shortlistTopics.find(t => t.id === topic.id);
      if (existing) {
        Object.assign(existing, topic);
      } else {
        this.shortlistTopics.push(topic);
      }
    })
    .then(this.getCandidateTopics)
    .catch(err => {
      console.error(err);
      return ui.showError({ err });
    })
    .finally(() => this.shortlistLoading = false);
  }, 500);

  @action getCandidateTopics = async () => {
    await this.searchIsReady();
    if (isEmpty(this.websiteShortlist)) return;
    return topicCtrl.getSubTopicsByParentId(this.websiteShortlist.id)
    .then(topics => this.candidateTopics.replace(topics))
    .then(this.getCandidateTopicGroups);
  };

  @action getCandidateTopicGroups = async () => {
    await this.searchIsReady();
    if (isEmpty(this.candidateTopics)) return;
    const groupIds = arrayFlat(this.candidateTopics.map(t => t.groupIdList.filter(id => id !== this.searchGroup?.id)));
    return Promise.all(groupIds.filter(id => !this.searchResultGroups.some(g => g.id === id)).map(client.getGroupById))
    .then(groups => this.candidateGroups.replace(groups as Group[]));
  };

  @action onProfileSave = async (id: Profile["id"]) => {
    const group = this.searchResultGroups.find(g => g.profileId === id);
    if (!group) return;
    return this.addToShortlist(group.id);
  };

  @action onProfileUnSave = async (id: Profile["id"]) => {
    const group = this.allResultGroups.find(g => g.profileId === id);
    if (!group) return;
    const topic = this.candidateTopics.find(t => t.groupIdList.includes(group.id));
    if (!topic) return;
    return this.removeFromShortlist(topic.id);
  };

  @action addToShortlist = async (groupId: number) => {
    if (isEmpty(this.websiteShortlist)) return;
    if (!this.searchGroup?.id) return;
    const selfMember = (this.searchGroup?.members || []).find(m => m.userId === client.userId);
    if (isEmpty(selfMember)) return;
    let group = this.searchResultGroups.find(g => g.id === groupId) as CAFCaregiverGroup & { rank?: number; total?: number; };
    if (isEmpty(group)) group = await client.getGroupById(groupId);
    let caregiverProfile = (toJS(group.profile) || {}).data;
    if (isEmpty(caregiverProfile)) caregiverProfile = (await client.getProfileById(group.profileId)).data;
    const careCircleProfile = toJS(this.searchForm.data);
    let cP = {}, ccP = {};
    for (let field in caregiverProfile) {
      if (caregiverProfile.hasOwnProperty(field)) {
        cP[`caregiver${capitalize(field)}`] = caregiverProfile[field];
      }
    }
    for (let field in careCircleProfile) {
      if (careCircleProfile.hasOwnProperty(field)) {
        ccP[`careCircle${capitalize(field)}`] = careCircleProfile[field];
      }
    }
    if (group.rank) ccP["rank"] = group.rank.toString();
    if (group.total) ccP["score"] = group.total.toString();
    const data = {
      ...cP,
      ...ccP
    };
    const topic: TopicPrimitive = {
      creatorMemberId: selfMember.id,
      typeId: topicTypeIds.candidate,
      description: abbvLastNameDisplayNameEng(
        getDisplayNameEng(caregiverProfile)
      ),
      parentId: this.websiteShortlist.id,
      isTemplate: 0,
      isParentTemplate: 0,
      typeClassId: typeClassIds.candidateTopic.v2.id,
      typeClassVersion: typeClassIds.candidateTopic.v2.version, // Default for now.
      data: JSON.stringify(data)
    };
    return api.POST({
      endpoint: endpointConfig.create_topic,
      data: {
        currentGroupId: this.searchGroup?.id,
        otherGroupIdList: [groupId],
        topic
      }
    })
    .then(this.getCandidateTopics);
  };

  @action removeFromShortlist = async (topicId: number) => {
    if (isEmpty(this.websiteShortlist)) return;
    return topicCtrl.deleteTopicById(topicId)
    .then(this.getCandidateTopics);
  };

  @action syncSavedProfileIds = () => stateCtrl.setMarketResultsPopupSavedProfileIds(this.savedProfileIds);

  onProfileChat = async (profileId: number) => {
    const group = this.allResultGroups.find(g => g.profileId === profileId);
    if (isEmpty(group)) return;
    const candidateTopic = this.candidateTopics.find(t => t.groupIdList.includes(group.id));
    if (isEmpty(candidateTopic)) return;
    return mcbSessionCtrl.postParentMessage({ openChat: candidateTopic.id });
  };

  @action toggleDebug = () => this.searchResultDebug = !this.searchResultDebug;
}

export default AvailabilityFinderController;
