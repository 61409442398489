import { env } from "../../config/env";

export const webAppUrls = {
  local: "http://localhost:3000",
  prod: "https://app.mycarebase.com",
  test: "https://testapp.mycarebase.com",
  dev: "https://devapp.mycarebase.com",
  // dev: "https://devj-3000.jateruy.com",
  debug: "http://localhost:3000"
};

export const webAppUrl = webAppUrls[env];

export const wpMyAccountUrl = "/my-account";

export const wpShopPresentationUrl = "/shop-internal";

export const wpCartUrl = "/cart";

export const wpCheckoutUrl = "/checkout";

export const wpCheckoutSuccessUrl = "/checkout/order-received";

export const wpCheckoutUrlList = [
  wpCartUrl,
  wpCheckoutUrl
];

export const signInUrl = "/Login";

export const signUpFamily = "/Registration?type=family";

export const signUpCaregiver = "/Caregiver";

export const signUpCaregiverNoReview = "/Registration?type=paid";

export const signUpUrl = "/join-mycarebase";


export const groupTypeIds = {
  caregiver: 3,
  careReceiver: 2,
  get careCircle() { return groupTypeIds.careReceiver },
  household: 7,
  cleaner: 8,
  handyman: 9,
  transportation: 10,
  myCareBaseStaff: 5
};

export const topicTypeIds = {
  shortlist: 8,
  candidate: 9,
  groupMessaging: 7,
  messaging: 10,

  timesheet: 11,
  shift: 2,
  task: 3,
  note: 4,
  issue: 5,

  mCBBroadcasts: 12,
  proactiveChat: 13,

  onboarding: 14,
  lead: 15
};

export const groupTypeRoleIds = {
  primaryFamilyCaregiver: 3,
  secondaryFamilyCaregiver: 4,
  careReceiver: 5,
  paidCaregiver: 6,
  healthProvider: 7,
  friend: 8,
  family: 11,

  caregiver: 9,

  primaryMember: 17,
  otherMember: 18,
  cleaner: 19,
  handyman: 20,
  transportation: 21,

  mCBStaff: 16
};

export const groupTypeActorIds = {
  [groupTypeIds.careReceiver]: {
    owner: 2,
    assigned: 3,
    participants: 4,
    observers: 5
  },
  [groupTypeIds.myCareBaseStaff]: {
    owner: 7,
    assigned: 8,
    participants: 9,
    observers: 10
  },
  [groupTypeIds.caregiver]: {
    owner: 6
  }
};

export const RoleGroupGroupTypeIds = [
  groupTypeIds.caregiver,
  groupTypeIds.cleaner,
  groupTypeIds.handyman,
  groupTypeIds.transportation
];

export const serviceRecipientGroupTypeIds = [
  groupTypeIds.careReceiver,
  groupTypeIds.household
];

export const serviceProviderGroupTypeIds = [
  groupTypeIds.cleaner,
  groupTypeIds.handyman,
  groupTypeIds.transportation
];

export const groupMemberWording = {
  singular: "a Member",
  plural: "Members"
};

export const groupTypeIdMatchMap = {
  [groupTypeIds.caregiver]: [
    groupTypeIds.careReceiver
  ],
  ...(() => {
    const result = {};
    for (const id of serviceProviderGroupTypeIds) {
      result[id] = [
        groupTypeIds.careReceiver,
        groupTypeIds.household
      ];
    }
    return result;
  })()
};

export const marketSearchUrls = (() => {
  const result = {};
  for (const groupTypeId of RoleGroupGroupTypeIds) {
    const keyName = Object.keys(groupTypeIds).find(k => groupTypeIds[k] === groupTypeId);
    if (keyName === "caregiver") {
      // result[groupTypeId] = "/home-care-service";
      result[groupTypeId] = (prov, city?) => `/a/senior-caregiver/${prov}${city ? `/${city}` : ""}`;
      continue;
    }
    // if (keyName === "cleaner") {
    //   result[groupTypeId] = "/a/cleaning";
    //   continue;
    // }
    result[groupTypeId] = `/a/${keyName}`;
  }
  return result;
})();

export const wpHostAddress = "/";

export const termsUrl = "/terms";

export const interviewAppointmentDurationOptions = [15, 30, 40];
// export const interviewAppointmentDurationOptions = [15, 30, 45, 60];

export const appointmentDefaultDuration = 30;

export const achievementColors = {
  bronze: "#CD7F32",
  silver: "#C0C0C0",
  gold: "#FFD700"
};