import { Controller } from "../../lib/controller";
import { action, computed, IObservableArray, observable, when } from "mobx";
import { Group, Profile } from "../../lib/types/dataTypes";
import { mcbBridge } from "../../client/mcb-bridge";
import { es6GetCurrURLQueryParams, isEmpty, whenFulfill } from "../../utils/helpers";
import { defaultCity, groupTypeIds } from "../../config/constants";
import { mcbSessionCtrl } from "../../client/mcb-bridge/session";
import { client } from "../../client/mcb-bridge/client";
import { findCityCoordinates } from "../../utils/geo-utilities";
import { Coordinate } from "../../lib/types/caf/geoDataTypes";
import { mcbSearchCtrl } from "../../client/mcb-bridge/search";
import { stateCtrl } from "../../client/mcb-bridge/state";
import { debounce } from "@material-ui/core";
import { globalCafHook } from "../../client/ref";

export interface McbFinderStore {
}
type Map = google.maps.Map;

export class McbFinderController extends Controller<McbFinderStore> {
  loginHandlerName = "McbFinderOnLoginGetProfile";
  logoutHandlerName = "McbFinderOnLogoutCleanup";
  map: Map;

  @observable userGroups: IObservableArray<Group> = observable([]);
  // @observable searchGroupId: number;

  // saveProfileDebounce;
  // saveProfileDebounceInterval = 1000;
  // saveProfileTimestamp: number;
  
  @observable zoom: number = 12;
  // @observable profileChecked: boolean;
  // @observable profile: Profile = {} as Profile;

  // readonly neighbourhoodsRadius = 40;
  @observable cityCoordinates: Coordinate;
  // @observable neighbourhoods: IObservableArray<Neighbourhood> = observable([]);
  @observable mode: "finder" | "editor";

  get appIsLoggedIn(): boolean {
    return mcbSessionCtrl.appIsLoggedIn;
  };
  // @computed get canShowEditor(): boolean {
  //   return this.appIsLoggedIn && !isEmpty(this.profile);
  // };
  @computed get defaultProfile(): Profile {
    return client.credentialReady && client.defaultProfile;
  };
  @computed get careCircleGroups(): Group[] {
    return this.userGroups.filter(g => g.typeId === groupTypeIds.careReceiver || g.typeId === groupTypeIds.myCareBaseStaff);
  };
  @computed get searchGroupId(): number {
    return stateCtrl?.finderContextGroupId || 0;
  };
  @computed get searchGroup(): Group {
    if (mcbSessionCtrl?.runningAuthChangeSequence) return null;
    if (!this.appIsLoggedIn) return mcbSearchCtrl.scratchpadGroup;
    return this.userGroups.find(g => g.id === this.searchGroupId);
  };
  // @computed get radiusMultiplier():number {
  //   if (this.zoom > 15) {
  //     return 10;
  //   } else if (this.zoom < 11) {
  //     return 1.2;
  //   } else{
  //     return (this.zoom - 10) * 2;
  //   }
  // };

  constructor() {
    super();
    this.initialize();
  }

  initialize = async () => {
    await mcbBridge.isInitialized();
    if (!globalCafHook?.bypassMode) {
      mcbBridge.addLoginEventListener(
        this.loginHandlerName,
        this.getContextSearchGroup
      );
      mcbBridge.addLogoutEventListener(
        this.logoutHandlerName,
        this.getContextSearchGroup
      );
    }
    return Promise.all([
      this.getCityCoordinates(),
      // this.getCityCoordinates().then(this.getNeighbourhoods),
      this.getContextSearchGroup()
    ]);
  };

  @action getCityCoordinates = () =>
    findCityCoordinates(defaultCity).then(coords => this.cityCoordinates = coords);

  @action getContextSearchGroup = debounce(async () => {
    await when(() => !mcbSessionCtrl.runningAuthChangeSequence);
    if (!this.appIsLoggedIn) {
      await whenFulfill(() => !isEmpty(mcbSearchCtrl.scratchpadGroup));
      stateCtrl.clearFinderContextGroupId();
      this.userGroups.clear();
      return stateCtrl.setFinderContextGroup(mcbSearchCtrl.scratchpadGroup.id);
    }
    await client.isLoggedInAndReady();
    await mcbSearchCtrl.checkMissingGroup(true, true, false);
    await whenFulfill(() => !isEmpty(mcbBridge.getCurrentLoggedInUserGroups()));
    this.userGroups.replace(mcbBridge.getCurrentLoggedInUserGroups());
    const { cafccid } = es6GetCurrURLQueryParams();
    const groupId = Number(cafccid);
    const group = !isNaN(groupId) && this.userGroups.find(g => g.id === groupId);
    if (group) return this.setLoggedInUserSearchGroupId(Number(cafccid));
    this.setLoggedInUserSearchGroupId(this.careCircleGroups[0].id);
  }, 1000);

  // @action getNeighbourhoods = async () => {
  //   // this.neighbourhoods = neighbourhoods;
  //   return mcbBridge.getNeighbourhoodsData(
  //     this.cityCoordinates.lat,
  //     this.cityCoordinates.lng,
  //     this.neighbourhoodsRadius
  //   )
  //   .then(neighbourhoods => this.neighbourhoods.replace(neighbourhoods));
  // };

  // getNeighbourhoodsData = async (lat: number, lng: number, radius: number) => {
  //   return api.GET({
  //     endpoint: endpointConfig.get_neighbourhoods_data(lat, lng, radius),
  //     headers: serverConfig.defaultHeaders,
  //   })
  //   .then(response => response.data);
  // };

  // @action getCaregiverProfile = async () => {
  //   const showError = err => ui.showError({ actionName: "Availability Finder", err });
  //   this.profileChecked = false;
  //   const userGroups = mcbBridge.getCurrentLoggedInUserGroups();
  //   // console.log(1, userGroups)
  //   if (isEmpty(userGroups)) return;
  //   const caregiverGroup = userGroups.find(
  //     (g) => g.typeId === groupTypeIds.caregiver
  //   );
  //   // console.log(2, caregiverGroup)
  //   if (isEmpty(caregiverGroup)) {
  //     this.profileChecked = true;
  //     const err = {
  //       name: "No Caregiver profile error",
  //       message: "No Caregiver profile found. Caregiver profile is required to access the availability editor."
  //     };
  //     return showError(err);
  //   }
  //   return api.GET({
  //       endpoint: endpointConfig.get_caf_profile(caregiverGroup.profileId),
  //     })
  //     .then(response => response.data)
  //     .then((profile: Profile) => {
  //       console.log("Got caregiver profile", profile);
  //       this.profile = this.ensureCAFProperties(profile);
  //       !this.mode && this.switchMode();
  //     })
  //     .catch(showError)
  //     .finally(() => this.profileChecked = true);
  // };

  // @action updateProfile = (newProfileData: CAFCaregiverProfileData) => {
  //   if (isEmpty(newProfileData)) {
  //     console.warn("Attempting to update profile with empty data.");
  //     return;
  //   }
  //   cleanupNeighbourhoodSetSequence(newProfileData);
  //   this.profile.data = newProfileData;
  //   const timestamp = new Date().getTime();
  //   this.saveProfileTimestamp = timestamp;
  //   this.autoSaveProfile(timestamp);
  //   return this.profile.data;
  // };

  // @action saveProfile = async (timestamp: number) => {
  //   if (!this.profile.id) {
  //     throw new Error("Profile does not have a valid ID.");
  //   }
  //   if (isEmpty(this.profile.data)) {
  //     throw new Error("Attempting to save profile with empty data.");
  //   }
  //   return mcbBridge
  //     .updateProfileDataById(this.profile.id, this.profile.data)
  //     .then((profile) => {
  //       const skipStateUpdate = timestamp !== this.saveProfileTimestamp;
  //       if (!skipStateUpdate) this.profile = profile;
  //     })
  //     .catch((err) => console.error(parseErrorMsg(err)));
  // };

  // @action autoSaveProfile = (timestamp: number) => {
  //   clearTimeout(this.saveProfileDebounce);
  //   const saveProfile = () => this.saveProfile(timestamp);
  //   this.saveProfileDebounce = setTimeout(
  //     saveProfile,
  //     this.saveProfileDebounceInterval
  //   );
  // };
  
  // @action onMapZoomChange = () => {
    // const oldZoom = this.zoom;
    // this.zoom = this.map && this.map.getZoom();
    // if(oldZoom > this.zoom){
    //   this.resetNeighbourhoods();
    // }
  // }

  setMapGlobal = (map:Map) => {
    this.map = map;
  };

  setLoggedInUserSearchGroupId = (id: number) => {
    const group = this.userGroups.find(g => g.id === id);
    if (!group) return;
    stateCtrl.setFinderContextGroup(id);
  };

  // ensureCAFProperties = (profile: Profile) => {
  //   if (isEmpty(profile)) return profile;
  //   const data = (profile as Profile<CAFCaregiverProfileData>).data;
  //   if (!data) return profile;
  //   if (!data.neighbourhoodSets) data.neighbourhoodSets = [];
  //   const defaultNeighbourhoodSet = data.neighbourhoodSets[0];
  //   if (
  //     isEmpty(defaultNeighbourhoodSet) ||
  //     isEmpty(defaultNeighbourhoodSet.neighbourhoodIds)
  //   ) {
  //     data.neighbourhoodSets[0] = defaultNeighbourhoodSetData;
  //   }
  //   if (!data.availability) data.availability = [];
  //   profile.data = data;
  //   return profile;
  // };

  // @action switchMode = () => this.mode = (!this.mode || this.mode === "finder") ? "editor" : "finder";

  // @action resetNeighbourhoods = async () => {
  //   this.zoom = this.zoom || (this.map && this.map.getZoom());
  //   const mapCenter: google.maps.LatLng =  this.map && this.map.getBounds() && this.map.getBounds().getCenter();
  //   const mapEdge: google.maps.LatLng = this.map && this.map.getBounds() && this.map.getBounds().getNorthEast();
  //   const viewRadius:Radius = mapCenter && mapEdge && getDistanceInKms(mapCenter,mapEdge);
  //   if (mapCenter && viewRadius){
  //     const neighbourhoods = await mcbBridge.getNeighbourhoodsData(mapCenter.lat(), mapCenter.lng(), this.radiusMultiplier*viewRadius);
  //     return this.neighbourhoods.replace(neighbourhoods);
  //   }
  // }

}
