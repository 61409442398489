import { Controller } from "../../lib/controller";
import { computed, when } from "mobx";
import { McbSearchController, mcbSearchCtrl } from "../../mcb/client/search";
import { FormLegacy } from "../../client/form.legacy";
import { PickerField, PickerOption, SupportedFieldTypes, TypeClassField } from "../../lib/types/formTypes.legacy";
import { groupTypeIds } from "../../mcb/config/constants";
import { Group, Profile } from "../../lib/types/dataTypes";
import { mcbSessionCtrl } from "../../mcb/client/session";
import { SearchResult } from "../../mcb/lib/types/dataTypes";
import { Topic } from "../../lib/types/topicTypes";
import { client } from "../../client/client";
import { filterFormFieldOptionsByService, filterFormFieldsByService } from "../../mcb/lib/common";
import { isEmpty } from "../../utils/helpers";
import { stateCtrl } from "../../client/state";
import { computedFn } from "mobx-utils";

export class McbMarketSearchController extends Controller {
  @computed get isLoggedIn(): boolean {
    return client.isLoggedIn;
  };
  @computed get appIsLoggedIn(): boolean {
    return mcbSessionCtrl.appIsLoggedIn && !mcbSessionCtrl.isInterimUser;
  };
  @computed get scratchpadGroup(): Group {
    return mcbSearchCtrl.scratchpadGroup;
  };

  @computed get availableServices(): McbSearchController["availableServices"] {
    return mcbSearchCtrl.availableServices || [];
  };
  @computed get selectedService(): number {
    return mcbSearchCtrl.selectedService;
  };
  @computed get provinceField(): TypeClassField<PickerField> {
    return (this.searchForm && this.searchForm.getRenderedField("provinces")) || {} as TypeClassField<PickerField>;
  };
  @computed get municipalityField(): TypeClassField<PickerField> {
    return (this.searchForm && this.searchForm.getRenderedField("municipalities")) || {} as TypeClassField<PickerField>;
  };
  @computed get municipalityOptions(): PickerOption[] {
    return !isEmpty(this.municipalityField.options) && this.municipalityField.options.filter(
      o => filterFormFieldOptionsByService(o, this.selectedService)
    );
  };

  @computed get searchForm(): FormLegacy {
    return mcbSearchCtrl.searchForm;
  };
  @computed get criteriaFields(): TypeClassField<SupportedFieldTypes>[] {
    if (!this.selectedService) return [];
    const fields = (this.searchForm && this.searchForm.renderable
    .filter(field => field.flags && field.flags.search));
    return filterFormFieldsByService(fields, this.selectedService) || [] as TypeClassField<SupportedFieldTypes>[];
  };
  @computed get criteriaDirty(): boolean {
    return this.searchForm && this.searchForm.isDirty;
  };

  @computed get geoLoading(): boolean {
    return mcbSearchCtrl.geoLoading;
  };
  @computed get searching(): boolean {
    return mcbSearchCtrl.searching;
  };
  @computed get searchReady(): boolean {
    return mcbSearchCtrl.ready;
  };
  @computed get searchResults(): SearchResult[] {
    const groups = mcbSearchCtrl.searchResults || [];
    // return groups.sort((a: SearchResult, b: SearchResult) => {
      // const aData = a.profile.data || {};
      // const bData = b.profile.data || {};
      // return (aData.publishedHourlyRate || 0) - (bData.publishedHourlyRate || 0);
    // });
    return groups;
  };
  @computed get searchResultProfiles(): Profile[] {
    return this.searchResults.map(group => group.profile);
  };
  @computed get allResultGroups(): SearchResult[] | Group[] {
    return mcbSearchCtrl.allResultGroups;
  };
  @computed get shortlistTopic(): Topic {
    return mcbSearchCtrl.shortlistTopic;
  };
  @computed get shortlistingAvailable(): boolean {
    return mcbSearchCtrl.shortlistingAvailable;
  };
  @computed get isCheckingShortlists(): boolean {
    return !!mcbSearchCtrl.isCheckingShortlists[this.scratchpadGroup.id];
  };
  @computed get isCheckingCandidates(): boolean {
    return mcbSearchCtrl.isCheckingCandidates;
  };
  @computed get candidateTopics(): Topic[] {
    return mcbSearchCtrl.candidateTopics || [];
  };
  @computed get candidateProfiles(): Profile[] {
    const groups = mcbSearchCtrl.candidateTopicGroups || [];
    return groups.map(group => group.profile);
  };
  @computed get savedProfileIds(): Profile["id"][] {
    return this.candidateProfiles.map(profile => profile.id);
  };
  @computed get ownProfileIds(): Profile["id"][] {
    return Array.from(new Set([
      ...client.groups.map(g => g.profileId),
      ...client.members.map(m => m.profileId)
    ]));
  };

  @computed get isCaregiver(): boolean {
    return this.selectedService === groupTypeIds.caregiver;
  };

  @computed get ratingLoading(): boolean {
    return mcbSearchCtrl.ratingLoading;
  };

  isReady = async () => Promise.all([
    when(() => this.searchReady),
    client.isLoggedInAndReady(),
    mcbSearchCtrl.candidateSyncReady(),
  ]);

  onServiceChange = (value: typeof groupTypeIds[keyof typeof groupTypeIds]) => mcbSearchCtrl.setService(value);

  onSearch = () => mcbSearchCtrl.searchListings();

  onGeo = () => mcbSearchCtrl.detectLocation().then(this.onSearch);

  onProfileSave = async (id: Profile["id"]) => {
    const group = this.searchResults.find(g => g.profileId === id);
    if (!group) return;
    return mcbSearchCtrl.addToShortlist(group.id);
  };

  onProfileUnSave = async (id: Profile["id"]) => {
    const group = this.allResultGroups.find(g => g.profileId === id);
    if (!group) return;
    const topic = this.candidateTopics.find(t => t.groupIdList.includes(group.id));
    if (!topic) return;
    return mcbSearchCtrl.removeFromShortlist(topic.id);
  };

  getProfileAvatarUri = (profile: Profile) => mcbSearchCtrl.getProfileAvatarUri(profile);

  getProfileAbilities = profile => mcbSearchCtrl.getProfileAbilities(profile);

  getProfileLanguages = profile => mcbSearchCtrl.getProfileLanguages(profile);

  setViewProfileId = id => {
    const group = this.allResultGroups.find(g => g.profileId === id);
    if (!group) return;
    // const displayName = getDisplayNameEng(removeLastName(group?.profile, true));
    // const url = displayName && `${window.location.pathname}/p/${safeUrlName(displayName, true)}`;
    // window.open(url, "_blank");
    mcbSearchCtrl.setViewGroupId(group.id);
    return stateCtrl.openMcbListingProfile(group.id);
  };

  openProfileViewGroupId = groupId => {
    mcbSearchCtrl.setViewGroupId(groupId);
    return stateCtrl.openMcbListingProfile(groupId);
  };

  getRatings = (visibleProfileIds: number[]) => mcbSearchCtrl.getRatingsForProfiles(visibleProfileIds);

  getAchievements = (visibleProfileIds: number[]) => mcbSearchCtrl.getAchievementForProfiles(visibleProfileIds);

  getRatingLoading = computedFn((profile: Profile): boolean => {
    const group = this.allResultGroups.find(g => g.profileId === profile?.id);
    return isEmpty(mcbSearchCtrl.findAggregatedRatingForProfile(profile.id))
    || mcbSearchCtrl.achievementLoadingGroupIds.includes(group.id);
  });

  findProfileRatingScore = computedFn((profile: Profile) => mcbSearchCtrl.findProfileRatingScore(profile));

  findProfileAchievement = computedFn((profile: Profile) => mcbSearchCtrl.findProfileAchievement(profile));

  getHasInterviewAppointmentData = (visibleProfileIds: number[]) => mcbSearchCtrl.getHasInterviewAppointmentDataForProfiles(visibleProfileIds);

  getHasInterviewLoading = computedFn((profile: Profile) => {
    const group = this.searchResults.find(g => g.profileId === profile?.id);
    if (!group) return false;
    if (mcbSessionCtrl.isValidVisitor) return false;
    return isEmpty(mcbSearchCtrl.findHasInterviewAppointmentData(this.scratchpadGroup.id, group.id));
  });

  findHasInterviewAppointment = computedFn((profile: Profile) => {
    const group = this.searchResults.find(g => g.profileId === profile?.id);
    if (!group) return false;
    const data = mcbSearchCtrl.findHasInterviewAppointmentData(this.scratchpadGroup.id, group.id);
    return data?.hasInterview;
  });
}